* {
  box-sizing: border-box;
}

body {
  background: linear-gradient(110deg, #fdcd3b 60%, #ffed4b 60%);
  min-height: 100vh;
}

/* textarea {
  font-family: inherit;
  font-size: inherit;
} */
textarea {
  color: rgba(0, 0, 0, 0.87);
  outline: none;
}